var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0" },
        [
          _c(
            "v-tabs",
            {
              ref: "tabs",
              staticClass: "mt-10",
              attrs: { height: "25", "show-arrows": "" },
              model: {
                value: _vm.tabView,
                callback: function ($$v) {
                  _vm.tabView = $$v
                },
                expression: "tabView",
              },
            },
            [
              _c("v-tabs-slider"),
              _c("v-tab", { attrs: { dark: "", width: "50" } }, [
                _c("span", { staticClass: "caption" }, [
                  _vm._v("query history"),
                ]),
              ]),
              _c(
                "v-tab-item",
                { staticClass: "mt-5" },
                [
                  _vm.snapshotQueries.length
                    ? _c("QueryHistory", {
                        attrs: { snapshotQueries: _vm.snapshotQueries },
                        on: {
                          queryResultsSuccess: function ($event) {
                            return _vm.moveToQueryPreview()
                          },
                          queryFilesSuccess: function ($event) {
                            return _vm.moveToQueryFiles()
                          },
                        },
                      })
                    : _c(
                        "v-alert",
                        { attrs: { prominent: "", type: "info", text: "" } },
                        [_vm._v("Query history is empty.")]
                      ),
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  attrs: {
                    dark: "",
                    height: "10",
                    disabled: _vm.disableQueryPreview,
                  },
                },
                [
                  _c("span", { staticClass: "caption" }, [
                    _vm._v("query preview"),
                  ]),
                ]
              ),
              _c(
                "v-tab-item",
                { staticClass: "mt-5" },
                [
                  _c("QueryTablePreview", {
                    on: {
                      querySubmitSuccess: function ($event) {
                        _vm.tabView = _vm.tabOptions.QUERY_HISTORY
                      },
                      queryFilesSaveSuccess: function ($event) {
                        _vm.tabView = _vm.tabOptions.QUERY_HISTORY
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  attrs: {
                    dark: "",
                    height: "10",
                    disabled: _vm.disableQueryFiles,
                  },
                },
                [
                  _c("span", { staticClass: "caption" }, [
                    _vm._v("Query Files"),
                  ]),
                ]
              ),
              _c("v-tab-item", { staticClass: "mt-3" }, [_c("QueryFiles")], 1),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }